@import url('https://fonts.googleapis.com/css2?family=Irish+Grover&family=Source+Sans+Pro:wght@400;600&display=swap');

html,
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  color: #fff;
  background-color: #000;
  font-family: 'Source Sans Pro', sans-serif;
}

#root {
  height: 100%;
  width: 100%;
}