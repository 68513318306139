#menu {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    list-style: none;
    margin: 0;
    padding: 0;
    opacity: 0.5;
    transition: opacity 0.3s;
}

#menu:hover {
    opacity: 1;
}

#menu button {
    appearance: none;
    background: none transparent;
    border: 0 none;
    position: relative;
    cursor: pointer;
    padding: 0.25rem 0.25rem 0.5rem;
    overflow: hidden;
    outline: 0 none;
}

#menu button:hover {
    overflow: visible;
}

#menu button::after {
    content: attr(data-tip);
    color: #000;
    background-color: #fff;
    padding: 0.15rem 0.25rem;
    position: absolute;
    right: 0.5rem;
    top: 100%;
    font-weight: 700;
    opacity: 0;
    transition: opacity 0.3s;
}

#menu button::before {
    content: "";
    display: block;
    height: 0;
    width: 0;
    border: 5px solid #fff;
    border-color: transparent transparent #fff;
    position: absolute;
    bottom: 0;
    left: 0.75rem;
    opacity: 0;
}

#menu button:hover::after,
#menu button:hover::before {
    opacity: 1;
    transition: opacity 0.3s;
}

#menu svg {
    width: 1.5rem;
    height: 1.5rem;
}

.offcanvas {
    color: #000;
}

.offcanvas-header {
    align-items: flex-start;
}

.offcanvas-title {
    padding-left: 1rem;
}

.offcanvas-title h2 {
    margin: 0;
}

.offcanvas-title p {
    margin: 0;
    font-size: 1.1rem;
}

.offcanvas-title span {
    display: inline-block;
    font-size: 0.8em;
    transform: translateY(0.8em);
}

.offcanvas-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.offcanvas-body .list-group li:first-of-type {
    padding-top: 0;
}

.offcanvas-body p {
    margin-bottom: 0.5rem;
}

.offcanvas-body .btn {
    margin: 0 0.25rem 0.25rem 0;
    font-weight: 600;
    font-size: 1.1rem;
    padding: 0.05rem 0.4rem;
}

.offcanvas-body .about {
    padding-left: 1rem;
    margin-top: 1rem;
}

.offcanvas-body .about p {
    margin: 0 0.5rem 0.5rem;
}

.offcanvas-body .about a {
    color: inherit;
}